import { useContractWrite, usePrepareTransactionRequest, useWaitForTransactionReceipt } from "wagmi";
import getENV from "../../../../../util/getENV.js";
import useSkeleKeys from "../../../../../hooks/ethereum/nft/skele/useSkeleKeys.js";

export default function UnclaimedKeys({ quantity }) {
    //console.log('>>> UnclaimedKeys')
    const { modules: { keys: { contract } } } = getENV();
    const { keys } = useSkeleKeys()

    const {
        write: claimKeys,
        status: claimStatus,
        error: claimError
    } = keys.claimKeys().config({ gasLimit: 1000000 });
    const prerevealURI = '/images/SKELEKEY.gif'
    const spinnerURI = '/images/spinny.gif'
    const claiming = ['signing', 'loading'].indexOf(claimStatus) > -1;
    if (quantity && prerevealURI) {
        return (
            <div onClick={claimKeys} className="thumbnail">
                <img
                    src={claiming ? spinnerURI : prerevealURI}
                    loading="lazy"
                    alt=""
                    className={'key-image'}
                />
                <h1 className="key-id unclaimed">{claiming ? `…` : `+${quantity}`}</h1>
            </div>
        )
    }
}