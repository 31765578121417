import { useEffect, useRef, useState } from "react";
import getRoman from "../../../../../util/getRoman.js";
import equiv from "../../../../../util/equiv.js";
import useObituary from "../../../../../hooks/ethereum/nft/skele/useObituary.js";
import { useAccount } from "wagmi";
import ImgLoader from "./ImgLoader.js";
import getENV from "../../../../../util/getENV.js";
const imgs = [];
export default function Thumbnail({ collection, tokenID, onClick, active, label = true }) {
    //console.log('>>> Thumbnail')
    const { modules } = getENV();
    const handleClick = (n) => {
        onClick(tokenID);
    }
    const { isConnected, address: wallet } = useAccount()
    const { obituary } = useObituary();
    let isPFP
    if (modules.obituary.address) {
        const pfpToken = obituary.getToken(wallet);
        isPFP = isConnected && pfpToken == tokenID;
    }
    const symbol = collection.symbol();
    const meta = collection.getMetadata(tokenID);
    const isKey = equiv(symbol, 'SKEYS')
    const isLocked = equiv(symbol, 'MYSKELE')
    const idString = isKey ? getRoman(tokenID) : `#${tokenID}`;
    const KEY_IMG = '/images/SKELEKEY.gif';
    const TOKEN_IMG = '/images/spinner.gif';
    const ACTIVE_IMG = '/images/demo/dark/blank.gif';
    const image = meta?.image;
    const [imgSRC, setImgSRC] = useState(isKey ? KEY_IMG : TOKEN_IMG);

    return (
        <div onClick={handleClick} className={'thumbnail'} style={{ marginBottom: label ? 0 : '1rem' }} >
            <ImgLoader
                src={image}
                onLoad={setImgSRC}
            />
            <div style={{ position: 'relative' }}>{isPFP && <div title='This is your PFP' style={{ position: "absolute", bottom: '.5rem', right: '.5rem' }}>👤</div>}
                <img
                    src={active ? (isKey ? KEY_IMG : ACTIVE_IMG) : imgSRC}
                    loading="lazy"
                    alt=""
                    className={isKey ? 'key-image' : 'profile-picture'}
                />
            </div>
            {label && <h1 style={isPFP ? { color: 'rgb(3, 255, 255)' } : {}} className={`${isKey ? 'key-id' : 'id-number'} ${isLocked ? 'sealed-at' : ''}`}>{idString}</h1>}
        </div>
    )
}