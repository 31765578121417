import { useState } from "react";
import getENV from "../../../../../util/getENV";
import defined from "../../../../../util/defined";

export default function ContractsMenu() {
    //console.log('>>> ContractsMenu')

    const { testnet, etherscan, modules } = getENV();
    const [showContracts, setShowContracts] = useState();
    const toggleContractsBar = () => { setShowContracts(!showContracts) }
    return (<>
        {showContracts ? <div onClick={toggleContractsBar} style={{ fontFamily: 'Silkscreen', position: 'fixed', zIndex: 999, width: '100%', display: 'flex', justifyContent: 'space-around', backgroundColor: (testnet ? '#402080' : '#202020') }}>
            {Object.entries(modules).map(x => {
                const [key, module] = x;
                if (module.contract.address) return <a key={key} onClick={e => e.stopPropagation()} className="eth" target="_blank" href={`${etherscan.address(module.contract.address)}#code`}>{module.icon}{module.contract.address?.substring(2, 4)}</a>
            })}
        </div> : <span style={{ position: 'fixed', right: '.25rem', zIndex: 999, fontFamily: 'Silkscreen' }} className="eth" onClick={toggleContractsBar}>📜</span>
        }
    </>)
}