import getENV from "../../../../util/getENV";
import useNFTCollection from "../useNFTCollection";

export default function useSkeleKeys() {
    // //console.log('>>> useSkeleKeys')
    const { modules: { keys: { contract } } } = getENV();
    const smartContract = useNFTCollection(contract);
    const { contract: keys } = smartContract;
    return { ...smartContract, keys }

}