
import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";

const getData = async (file) => {
    const res = await fetch(file);
    const reader = res.body.getReader();
    const decoder = new TextDecoder('utf-8');
    const text = await reader.read();
    const rawData = decoder.decode(text.value);
    return rawData
        .split('\n')
        .filter((row) => {
            return (row.length > 0);
        });
};


export const getMerkleRoot = async (file) => {
    const data = await getData(file);

    const leaves = data.map((item) => {
        return keccak256(item);
    });

    const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    const hexRoot = merkleTree.getHexRoot();
    return hexRoot
}
export const getMerkleProofFromFile = async (input, file) => {
    if (!input) {
        return [];
    }

    const data = await getData(file);
    return getMerkleProofFromArray(input, data);
}

export const getMerkleProofFromArray = (input, data) => {
    if (!input) {
        return [];
    }


    const leaves = data.map((item) => {
        return keccak256(item);
    });

    const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });

    return merkleTree.getHexProof(keccak256(input));
}


/******************/



















