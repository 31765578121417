import { useState } from "react";
import TxButton from "./TxButton";
import defined from "../../../../../util/defined";

export default function TxInputButton({ label, status, loading, disabled, placeholder, value, onInput, keyPlaceholder, key, onKeyInput, onSubmit, submitEnabled, submitLabel }) {
    //console.log('>>> TxInputButton')
    const [input, setInput] = useState();
    const [keyInput, setKeyInput] = useState();
    const startInput = () => setInput(true);
    const handleInput = e => onInput(e.target.value)
    const handleKeyInput = e => onKeyInput(e.target.value)
    const clearInput = () => {
        onInput?.('');
        onKeyInput?.('')
        setInput(false);
    }
    const handleClick = () => {
        onSubmit();
        clearInput()
    }
    return (<>
        {!input ? <TxButton
            disabled={disabled}
            onClick={startInput}
            status={status}
            label={label}
            loading={loading}
        />
            : <div className="input">
                {defined(key, onKeyInput, keyPlaceholder) && <input type="text" value={key} onInput={handleKeyInput} className="address-input" placeholder={keyPlaceholder}></input>}
                <input type="text" value={value} onInput={handleInput} className="address-input" placeholder={placeholder}></input>
                <button className={`${submitEnabled ? 'send-button' : 'cancel-button'}`} onClick={submitEnabled ? handleClick : clearInput} >{submitEnabled ? submitLabel : 'X'}</button>
            </div>}
    </>)
}