import getENV from "../../../../util/getENV";
import useSmartContract from "../../useSmartContract";

export default function useGraveyard() {
    // //console.log('>>> useGraveyard')
    const { modules: { graveyard: { contract } } } = getENV();
    const smartContract = useSmartContract(contract);
    const { contract: graveyard } = smartContract;
    return { ...smartContract, graveyard }

}