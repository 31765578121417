import getENV from "../../../../util/getENV";
import useSmartContract from "../../useSmartContract";

export default function useObituary() {
    // //console.log('>>> useObituary')
    const { modules: { obituary: { contract } } } = getENV();
    const smartContract = useSmartContract(contract);
    const { contract: obituary } = smartContract;
    return { ...smartContract, obituary }

}