import { useRef, useState } from "react";

let timer;
/**
 * Custom React hook for managing a redraw mechanism.
 * @returns {{ update: number, redraw: Function }} An object containing the update value and the redraw function.
 */
export default function useRedraw() {
    // //console.log('>>> useRedraw')
    const timer = useRef();
    const [, forceUpdate] = useState();
    /**
     * Updates the internal state and triggers a redraw.
     * @private
     */
    const update = () => {
        //console.log('updating')
        forceUpdate(Date.now());//trigger a redraw
    }
    /**
     * Flags the app for redraw, optionally forcing an immediate redraw.
     * @param {boolean} [force=false] Whether to force an immediate redraw.
     */
    const redraw = (force) => {
        clearTimeout(timer.current);//reset bundling timer on every call to redraw
        if (force) {
            update();//do not wait to bundle more requests, just update now (important for UI update before opening wallet)
        } else {
            //console.log('batching')
            timer.current = setTimeout(update, 300);//0.3s rolling delay to bundle multiple calls into a single update unless forced sooner
        }
    }
    return {

        /**
         * Function to trigger a redraw.
         * @type {Function}
         */
        redraw
    }
}