import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTracking = (title) => {
  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
        page_title: title,
    });
  }, [location]);
};

export default usePageTracking;