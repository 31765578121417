import GAD from "../../../../util/GAD";
import defined from "../../../../util/defined";
import getENV from "../../../../util/getENV";
import useNFTCollection from "../useNFTCollection";
import useSkelephunks from "./useSkelephunks";

export default function useCatacombs() {
    // //console.log('>>> useCatacombs')
    const { modules: { catacombs: { contract } } } = getENV();
    const { skelephunks } = useSkelephunks(contract);
    const getTokenInfo = skelephunks.getTokenInfo;
    skelephunks.getTokenInfo = (id) => {
        const tokenInfo = getTokenInfo(id)
        return {
            ...getTokenInfo(id),
            lockedAt: skelephunks.lockedAt(id)
        }
    }
    return { ...skelephunks, catacombs: skelephunks, ext: skelephunks }
}