import { useAccount, useEnsName } from "wagmi";
import getENV from "../../../util/getENV";
import { Link, useParams } from "react-router-dom";
import Header from "./modules/Header";
import { useState } from "react";
import useObituary from "../../../hooks/ethereum/nft/skele/useObituary";
import defined from "../../../util/defined";
import Headshot from "./modules/Headshot";
import useCatacombs from "../../../hooks/ethereum/nft/skele/useCatacombs";
import useSkelephunks from "../../../hooks/ethereum/nft/skele/useSkelephunks";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import usePageTracking from "../../../hooks/usePageTracking";

export default function Profile() {
    //console.log('>>> Profile')

    usePageTracking('Profile');
    const { override, modules, wagmi: { mainnetChainID: mainnetChain } } = getENV();
    const account = useAccount();
    let isConnected, accountWallet
    if (override) {
        isConnected = true;
        accountWallet = override
    } else {
        isConnected = account.isConnected;
        accountWallet = account.address;
    }
    const params = useParams();
    const { obituary } = useObituary();
    const { catacombs } = useCatacombs();
    const { skelephunks } = useSkelephunks();
    const wallet = defined(params.tag) ? obituary.getAddress(params.tag?.toLowerCase()) : accountWallet;
    const hasProfile = obituary.hasIdentity(wallet);
    const hasTokens = skelephunks.balanceOf(wallet) + catacombs.balanceOf(wallet) > 0;
    const { open } = useWeb3Modal();
    const [edit, setEdit] = useState(true);
    return (<><div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">
        <Header subtitle="Your Identity" />
        <div className="network">
            {hasProfile ? <> <Headshot />
            </> : <><div> <Link onClick={() => { if (!isConnected) open(); }} to={!hasTokens ? '/mint' : '/inventory'} className="cta">{!isConnected || !hasTokens ? 'MINT' : 'SELECT'} A TOKEN</Link>
            </div><div className="modify-note">to set up your profile</div></>
            }
        </div >
    </div ></>
    )
}

<div className="top">

</div>