import { Link } from "react-router-dom";
import ActionButton from "./ActionButton";
import { Navigate } from "react-router";

export default function LinkButton({ label, to, cssClass, disabled }) {
    return (
        <Link to={to}>
            <ActionButton
                label={label}
                cssClass={cssClass}
                disabled={disabled}
            />
        </Link>
    )
}