export default function getRoman(num) {
    // //console.log('>>> getRoman')

    let numeral = '';
    let remain = num;
    const thousands = Math.floor(remain / 1000);
    for (let m = 0; m < thousands; m++) numeral = `${numeral}M`;
    remain -= thousands * 1000;

    const nineHundreds = Math.floor(remain / 900);
    for (let cm = 0; cm < nineHundreds; cm++) numeral = `${numeral}CM`;
    remain -= nineHundreds * 900;

    const fiveHundreds = Math.floor(remain / 500);
    for (let d = 0; d < fiveHundreds; d++) numeral = `${numeral}D`;
    remain -= fiveHundreds * 500;

    const fourHundreds = Math.floor(remain / 400);
    for (let cd = 0; cd < fourHundreds; cd++) numeral = `${numeral}CD`;
    remain -= fourHundreds * 400;

    const hundreds = Math.floor(remain / 100);
    for (let c = 0; c < hundreds; c++) numeral = `${numeral}C`;
    remain -= hundreds * 100;

    const nineties = Math.floor(remain / 90);
    for (let xc = 0; xc < nineties; xc++) numeral = `${numeral}XC`;
    remain -= nineties * 90;

    const fifties = Math.floor(remain / 50);
    for (let l = 0; l < fifties; l++) numeral = `${numeral}L`;
    remain -= fifties * 50;

    const forties = Math.floor(remain / 40);
    for (let xl = 0; xl < forties; xl++) numeral = `${numeral}XL`;
    remain -= forties * 40;

    const tens = Math.floor(remain / 10);
    for (let xl = 0; xl < tens; xl++) numeral = `${numeral}X`;
    remain -= tens * 10;

    const nines = Math.floor(remain / 9);
    for (let ix = 0; ix < nines; ix++) numeral = `${numeral}IX`;
    remain -= nines * 9;

    const fives = Math.floor(remain / 5);
    for (let v = 0; v < fives; v++) numeral = `${numeral}V`;
    remain -= fives * 5;

    const fours = Math.floor(remain / 4);
    for (let iv = 0; iv < fours; iv++) numeral = `${numeral}IV`;
    remain -= fours * 4;

    const ones = Math.floor(remain / 1);
    for (let i = 0; i < ones; i++) numeral = `${numeral}I`;
    remain -= ones * 1;

    return numeral

}