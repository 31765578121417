import {
    useState
} from 'react';

import {
    useAccount,
} from "wagmi";

import getENV from '../../../util/getENV.js';
import UnclaimedKeys from './modules/elements/UnclaimedKeys.js';
import Collection from './modules/Collection.js';
import useSkelephunks from '../../../hooks/ethereum/nft/skele/useSkelephunks.js';
import scrollToAnchor from '../../../util/scrollToAnchor.js';
import Header from './modules/Header.js';
import defined from '../../../util/defined.js';
import { Link } from 'react-router-dom';
import ConnectButton from './modules/elements/ConnectButton.js';
import PROMO from './modules/PROMO.js';
import useSkeleKeys from '../../../hooks/ethereum/nft/skele/useSkeleKeys.js';
import useCatacombs from '../../../hooks/ethereum/nft/skele/useCatacombs.js';
import useObituary from '../../../hooks/ethereum/nft/skele/useObituary.js';
import usePageTracking from '../../../hooks/usePageTracking.js';
export default function Inventory({gwei}) {
    //console.log('>>> Inventory')

    usePageTracking('Inventory');
    const BATCH_SIZE = 21;
    const { override, collection: { startingIndex }, modules } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    //balances
    //tokens
    const { skelephunks } = useSkelephunks();
    const { keys } = useSkeleKeys();
    const { catacombs } = useCatacombs()

    const { tokenIDs: keysList, balance: keysBalance } = keys.tokensOfOwner(wallet);
    let lockedBalance;
    if (modules.catacombs.address) {
        const lockedBalance = catacombs.balenceOf(wallet);
    }
    const unclaimedKeys = keys.unclaimedOf(wallet)

    const { balance: tokenBalance } = skelephunks.tokensOfOwner(wallet);
    const claimUntil = unclaimedKeys > 0 ? keys.getClaimDeadline(wallet) : 0;
    const hasTokens = tokenBalance > 0;

    const { obituary } = useObituary();
    if (modules.obituary.address) {
        const hasProfile = isConnected && obituary.hasIdentity(wallet);
        const profileTag = obituary.getName(wallet);
        const profileToken = obituary.getToken(wallet);
    }
    const hasLockedTokens = lockedBalance > 0;
    const numKeys = keysList?.length || 0;
    const skeleSize = tokenBalance + lockedBalance;
    // const inventorySize = skeleSize + keysBalance;
    // const hasInventory = inventorySize > 0;
    // const loadedSize = tokens?.length || 0 + lockedList?.length || 0 + keysList?.length || 0;
    // const loaded = hasInventory && inventorySize == loadedSize;


    return (<>
        <div className="landing wf-section">
            <Header gwei={gwei}  subtitle="Your Collection" />
            {true && <div className="modify-note">come back at any time to update the gender or direction of your Skelephunk{skeleSize > 1 ? 's' : ''}</div>}
            <div id="inventory" className="inventory">
                {isConnected ? <>
                    {unclaimedKeys + numKeys > 0 && <>
                        <div className="div-block-18">
                            <h3 className="skeleton-keys">Skeleton Keys</h3>
                            <div className="keys-info">
                                <div>?</div>
                                <div className="key-info-text">
                                    <div className="key-bubble">
                                        <div className="congratulations">Congratulations!</div>
                                        <div>A skeleton key is rare skelephunks treasure.</div>
                                        {!!keysBalance && <div>Click a key to learn how to use it.</div>}
                                        {!!unclaimedKeys && <>
                                            <div className="claim-note">
                                                You have {unclaimedKeys} unclaimed key{unclaimedKeys > 1 && 's'}.
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Collection collection={keys} wallet={wallet} mode={'grid'} toggle={false} />
                        {//unclaimed keys
                            !!unclaimedKeys && <UnclaimedKeys quantity={unclaimedKeys} />
                        }
                        {!!unclaimedKeys && <div className="unclaimed-info">
                            <div className="claim">
                                You have {unclaimedKeys} unclaimed key{unclaimedKeys > 1 && 's'}. Click the <span className="unclaimed">+{unclaimedKeys}</span> to claim.
                            </div>
                            <div className="claim-date">
                                (your key{unclaimedKeys > 1 && 's'} can be claimed until {new Date(claimUntil).toLocaleDateString()})
                            </div>
                        </div>}
                    </>}
                    {defined(lockedBalance) || defined(tokenBalance) ? <>
                        {hasLockedTokens && <>
                            <h3 className="skeleton-keys">
                                {lockedBalance} In the Catacombs{/*tokenBalance !== 1 && 's'*/}
                            </h3>
                            <Collection wallet={wallet} collection={catacombs} toggle={false} />
                        </>}
                        {!hasLockedTokens || hasTokens &&
                            <h3 className="skeleton-keys">
                                {!hasTokens ? 'No' : tokenBalance > 7 ? tokenBalance : ''} {lockedBalance > 0 ? 'More ' : ''}Skelephunk{tokenBalance !== 1 && 's'}
                            </h3>
                        }
                        {hasTokens && <Collection wallet={wallet} collection={skelephunks} toggle={true} />}
                        {tokenBalance + lockedBalance == 0 && <div className="top">
                            <Link to={'/mint'} className="cta">MINT YOURS NOW</Link>
                        </div>}
                    </> : <div id="contentSpinner" className="spinner"><img src="/images/spinner.gif" /></div>}

                    {!!(tokenBalance + lockedBalance + numKeys) && <>

                        <div className="modify-note">exclusive access for holders:</div>
                        <PROMO />
                        <div className="top">
                            <div onClick={() => { scrollToAnchor('inventory') }} className="cta">back to top</div>
                        </div></>}
                </> : <><div className="mint-info free">Connect your wallet to view your Skelephunks</div><div><ConnectButton /></div></>}
            </div>
        </div >
    </>)
}