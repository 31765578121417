//
// Author: Skelephunks 
//

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

console.log(`
* ————————————————————————————————————————————————————————————————————————————————— *
|                                                                                   |
|    SSSSS K    K EEEEEE L      EEEEEE PPPPP  H    H U    U N     N K    K  SSSSS   |
|   S      K   K  E      L      E      P    P H    H U    U N N   N K   K  S        |
|    SSSS  KKKK   EEE    L      EEE    PPPPP  HHHHHH U    U N  N  N KKKK    SSSS    |
|        S K   K  E      L      E      P      H    H U    U N   N N K   K       S   |
|   SSSSS  K    K EEEEEE LLLLLL EEEEEE P      H    H  UUUU  N     N K    K SSSSS    |
|                                                                                   |
| * AN ETHEREUM-BASED INDENTITY PLATFORM BROUGHT TO YOU BY NEUROMANTIC INDUSTRIES * |
|                                                                                   |
|                             @@@@@@@@@@@@@@@@@@@@@@@@                              |
|                             @@@@@@@@@@@@@@@@@@@@@@@@                              |
|                          @@@,,,,,,,,,,,,,,,,,,,,,,,,@@@                           |
|                       @@@,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,@@@                        |
|                       @@@,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,@@@                        |
|                       @@@,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,@@@                        |
|                       @@@,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,@@@                        |
|                       @@@@@@@@@@,,,,,,,,,,@@@@@@,,,,,,,@@@                        |
|                       @@@@@@@@@@,,,,,,,,,,@@@@@@,,,,,,,@@@                        |
|                       @@@@@@@@@@,,,,,,,,,,@@@@@@,,,,,,,@@@                        |
|                       @@@,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,@@@                        |
|                       @@@,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,@@@                        |
|                       @@@,,,,,,,@@@@@@,,,,,,,,,,,,,,,,,@@@                        |
|                       @@@,,,,,,,@@@@@@,,,,,,,,,,,,,,,,,@@@                        |
|                          @@@,,,,,,,,,,,,,,,,,,,,,,,,@@@                           |
|                          @@@,,,,,,,,,,,,,,,,,,,,@@@@@@@                           |
|                             @@@@@@@@@@@@@@@@@@@@@@@@@@@                           |
|                             @@@@@@@@@@@@@@@@@@@@@@@@@@@                           |
|                             @@@@,,,,,,,,,,,,,,,,@@@@,,,@@@                        |
|                                 @@@@@@@@@@@@@@@@,,,,@@@                           |
|                                           @@@,,,,,,,,,,@@@                        |
|                                           @@@,,,,,,,,,,@@@                        |
|                                              @@@,,,,@@@                           |
|                                           @@@,,,,,,,,,,@@@                        |
|                                                                                   |
|                                                                                   |
|   for more information visit skelephunks.com  |  follow @skelephunks on twitter   |
|                                                                                   |
* ————————————————————————————————————————————————————————————————————————————————— *
`
)
