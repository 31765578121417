import { Link, Navigate, useParams } from "react-router-dom";
import Header from "./modules/Header";
import useSkelephunks from "../../../hooks/ethereum/nft/skele/useSkelephunks";
import CLAIM from "./modules/CLAIM";
import getENV from "../../../util/getENV";
import useSmartContract from "../../../hooks/ethereum/useSmartContract";
import { useAccount, useEnsAddress, useEnsName } from "wagmi";
import useMerkle from "../../../hooks/useMerkle";
// import { Web3Modal, useWeb3Modal } from "@web3modal/wagmi/react";
import equiv from "../../../util/equiv";
import defined from "../../../util/defined";
import { useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ConnectButton from "./modules/elements/ConnectButton";
import useObituary from "../../../hooks/ethereum/nft/skele/useObituary";
import { mainnet } from "viem/chains";
import { normalize } from 'viem/ens';
import TxInputButton from "./modules/elements/TxInputButton";
import usePageTracking from "../../../hooks/usePageTracking";
import LinkButton from "./modules/elements/LinkButton";
import useLocalState from "../../../hooks/useLocalState";

export default function Drop() {
    //console.log('>>> Drop')

    usePageTracking('Drop');
    const { walletID } = useParams();
    usePageTracking(`Drop${walletID ? ' ' + walletID : ''}`);
    const { mainnetChainID, override, modules: { drop: { contract } }, promo: { drop: dropPromo }, wagmi: wagmiConfig } = getENV();
    const { obituary } = useObituary()
    const account = useAccount();
    let isConnected, wallet;
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const tester = defined(walletID);
    const displayWallet = tester ? walletID : wallet;
    const tagAddress = obituary.getAddress(walletID);
    const ens = useEnsAddress({
        chainId: mainnet.id,
        name: normalize(walletID),
    })?.data
    const address = tester ? ens || tagAddress || walletID : wallet;
    const { data: name } = useEnsName({ address, chainId: 1 });
    const { data: ensData, status: ensStatus, error: ensError } = useEnsName({ address: wallet, chainId: 1 });
    const tag = tagAddress ? walletID : undefined;
    const { drops: { proof } } = useMerkle(address);
    const { contract: drop } = useSmartContract(contract);
    const numDrops = drop?.claimableDropsForWallet(address, proof);
    const { skelephunks } = useSkelephunks();
    const numTokens = skelephunks.balanceOf(address);
    const { open } = useWeb3Modal();
    const [promoClicked, setPromoClicked] = useLocalState('promo-clicked');
    const clickPromo = () => {
        setPromoClicked(true);
    }
    const ensureConnection = () => {
        if (!isConnected) open();
    }
    const handleAddressInput = (e) => { setAddr(e.value) };
    const [addr, setAddr] = useState();
    const validAddress = addr?.match(/^0x[a-fA-F0-9]{40}$/);

    const toAddress = equiv(validAddress, addr) ? addr : ens;
    const checkWallet = () => {
        Navigate.to(`/drop/${toAddress}`);
    }
    const displayName = tag || name || displayWallet.substring(0, 7)
    return (<div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">
        <Header subtitle={`${!tester ? 'The ' : ''}Drop${tester ? ' Status' : ''}`} />
        {tester && <Link className="token-name free" to={`/wallet/${address}`}>{displayName}</Link>}
        {isConnected || tester && <h3 className="skeleton-keys">{defined(walletID) ? 'Has' : 'You Have'} {numTokens} Skelephunk{numTokens == 1 ? '' : 's'} </h3>}
        <div id="inventory" className="inventory wf-section">


            <div className="list">


                {tester ? <>
                    {walletID.indexOf('0x') !== 0 && <div className="mint-info">{address.substr(0, 13)}</div>}
                    {numDrops > 0 ? <>
                        {/* <div className="id-number">{displayName}</div> */}
                        <h1 className="key-name" >HAS {numDrops} DROP{numDrops == 1 ? '' : 'S'}</h1>
                        {!isConnected && <div className="token-name">YOURS?</div>}
                        {(!isConnected || wallet === walletID) &&
                            <div className="top">
                                <Link to={'/drop'} onClick={ensureConnection} className="cta">CLAIM {numDrops == 1 ? 'IT' : 'THEM'} NOW</Link>
                            </div>}
                    </>
                        : <>
                            <h1 className="key-name">NO DROPS</h1>
                            <div className="mint-info error">no {numTokens > 0 ? 'new ' : ''}drop found for this wallet</div>
                            <div className="top">
                                <Link to={'/mint'} onClick={ensureConnection} className="cta">MINT NOW</Link>
                            </div>
                        </>}
                </> : <>
                    {!numDrops && defined(dropPromo) && <div className="top">
                        <a onClick={clickPromo} href={dropPromo.link} target="_blank" className="freedrop"><span className="emoji">💧</span>{dropPromo.button}</a>
                    </div>}
                    {isConnected ? <>
                        <> {!tester && numDrops > 0 && <CLAIM name={displayName} address={address} />}</>
                        {numTokens > 0 && <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <LinkButton
                                to={'/inventory'}
                                label="VIEW YOUR SKELES"
                            />
                        </div>}

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link to={'/mint'} className="cta">MINT {numTokens === 0 ? 'YOURS' : 'MORE'} NOW</Link>
                        </div>
                    </> : <>
                        <div style={{ margin: "auto", padding: "1rem" }}><ConnectButton /></div>

                        <TxInputButton
                            label="or check an address for unclaimed drops"
                            placeholder="enter an 0x or ens"
                            value={addr}
                            onInput={handleAddressInput}
                            submitEnabled={defined(toAddress)}
                            onSubmit={checkWallet}
                            submitLabel="check"
                        />
                    </>}
                </>}
            </div>


            {!isConnected && !tester && <div className="mint-info">if you have drops to claim they will appear here</div>}
        </div >
    </div >
    )
}
