export default function ActionButton({ label, onClick, disabled, cssClass }) {
    //console.log('>>> ActionButton')

    return (
        <div
            disabled={disabled}
            className={`action w-button catacombs ${cssClass}`}
            onClick={onClick}
        >
            {label}
        </div>
    )
}