import { useState } from "react";

export default function useLocalState(key, value) {
    const local = localStorage.getItem(key)
    value = local ? JSON.parse(local) : value;
    const [state, setState] = useState(value)
    const storeState = (value)=>{
        localStorage.setItem(key, value);
        setState(value);
    }
    return [state, storeState];
}