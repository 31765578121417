import getENV from "../../../../util/getENV";
import useSmartContract from "../../useSmartContract";

export default function useSkeleDrop() {
    // //console.log('>>> useSkeleDrop')
    const { modules: { drop: { contract } } } = getENV();
    const smartContract = useSmartContract(contract);
    const { contract: drop } = smartContract;
    return { ...smartContract, drop }

}