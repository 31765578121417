//
// Author: Skelephunks 
//
import { createWeb3Modal } from '@web3modal/wagmi/react'




import getENV from '../../util/getENV.js';
import ContractsMenu from './screens/modules/elements/ContractsMenu.js';
import BRB from './screens/modules/BRB.js';
import AppRouter from './AppRouter.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { WagmiProvider, deserialize, serialize } from 'wagmi'


const { projectId, status: { network: networkStatus }, override, wagmi: { config: wagmiConfig, chains } } = getENV();



createWeb3Modal({ wagmiConfig, projectId });

// 1. Import modules.
// 2. Create a new Query Client with a default `gcTime`.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1_000 * 60 * 60 * 24, // 24 hours
    },
  },
})

// 3. Set up the persister.
// const persister = createAsyncStoragePersister({
//   serialize,
//   storage: indexedDB,
//   deserialize,
// })
export default function App() {
  //console.log('>>> App')
  console.log(`//////////////// STARTING APP /////////////////`)
  return (<>
    <ContractsMenu />
    <div className="home">
      {!networkStatus ?
        <BRB /> :
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            {/* <PersistQueryClientProvider
              client={queryClient}
              persistOptions={{ persister }}
            > */}
            <AppRouter />
            {/* </PersistQueryClientProvider> */}

          </WagmiProvider>
        </QueryClientProvider>
      }
    </div>
  </>)
}
