export default function UnloadedTokens({ quantity, clickHandler }) {
  //console.log('>>> UnloadedTokens')
  return (
    <div onClick={clickHandler} className="unloaded">
      <div className="composite">
        <div className="count">+</div>
        <img
          src='/images/demo/dark/blank.gif'
          loading="lazy"
          alt=""
          className={'profile-picture'}
        />
      </div>
      <h1 className="more">+{quantity}</h1>
    </div>
  )
}