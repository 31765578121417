const LogLevel = {
    SILENT : 0,
    ALERT: 1,
    ERROR: 2,
    WARN:3,
    INFO:4,
    DEBUG:5,
    VERBOSE:6
}
let logLevel = LogLevel.SILENT;
const setLogLevel = level => logLevel = level;
const log =  (arg)=> console.log (arg);
const trace =  (msg, arg)=> log (`${msg}${arg?'('+(arg)+')':''}`);
const fatal =  (msg, arg)=> logLevel > LogLevel.SILENT     ? trace (`ALERT:    ${msg}`, arg) : '';
const alert =  (msg, arg)=> logLevel >= LogLevel.ALERT      ? trace (`ALERT:    ${msg}`, arg) : '';
const error =  (msg, arg)=> logLevel >= LogLevel.ERROR      ? trace (`ERROR:    ${msg}`, arg) : '';
const warn =   (msg, arg)=> logLevel >= LogLevel.WARN       ? trace (`WARN:     ${msg}`, arg) : '';
const info =   (msg, arg)=> logLevel >= LogLevel.INFO       ? trace (`INFO:     ${msg}`, arg) : '';
const debug =  (msg, arg)=> logLevel >= LogLevel.DEBUG      ? trace (`DEBUG:    ${msg}`, arg) : '';
const verbose =(msg, arg)=> logLevel >= LogLevel.VERBOSE    ? trace (`VERBOSE:  ${msg}`, arg) : '';
export {
    LogLevel, 
    setLogLevel, 
    log, 
    trace, 
    fatal, 
    alert, 
    error, 
    warn, 
    info, 
    debug, 
    verbose
}