
import { goerli, sepolia, mainnet } from '@wagmi/core/chains'
import SKELEPHUNKS_ABI from '../abi/skele/SkelephunksABI.json';
import SKELEKEYS_ABI from '../abi/skele/SkeletonKeysABI.json';
import GRAVEYARD_ABI from '../abi/skele/GraveyardABI.json';
import DROP_ABI from '../abi/skele/DropABI.json';
import OBITUARY_ABI from '../abi/skele/ObituaryABI.json';
import GRIDTAG_ABI from '../abi/grid/GRiDTagABI.json';
import GRIDIDENTITY_ABI from '../abi/grid/GRiDIdentityABI.json';
import GRIDSTORE_ABI from '../abi/grid/GRiDStoreABI.json';
import CATACOMBS_ABI from '../abi/skele/CatacombsABI.json';
import { webSocket } from 'viem';
import { defaultWagmiConfig } from '@web3modal/wagmi';


const
    OVERRIDE = null,//'0x7cbe5ba169bc3b69e7fb5669627456c52dcfd46d',//
    TESTNET = null,//'sepolia', //
    INFURA = {
        [mainnet.id]: webSocket('wss://mainnet.infura.io/ws/v3/7ed333d031f74118a752a02dc4dcab86'),
        [sepolia.id]: webSocket('wss://sepolia.infura.io/ws/v3/7ed333d031f74118a752a02dc4dcab86')
    },
    // SKELEPHUNKS_GOERLI = '0xbac6c9f6b0d7be7a46edb82237991fb990459748',
    // SKELEKEYS_GOERLI = '0xEcbb347884CF00a535079B77dc490DB0c3Af82a2',
    // GRAVEYARD_GOERLI = '0x1EFb822b95aD4B9814673224a475aD617D8C90Fd',
    // DROP_GOERLI = '0x05e59387B96b8E7c5be80cAEAD9Efec48b868377',
    // CATACOMBS_GOERLI = '0x7a2ba5aD236c89a6f23DaAA549bA454bcA63E208',
    // GRIDTAG_GOERLI = '0x2bDee7c88e696aacdE42144D56B294f6D80c133a',
    // GRIDIDENTITY_GOERLI = '0x535Fdf26E384c2ec0007302ED7972957c7d05fF4',
    // GRIDSTORE_GOERLI = '0xE309182bBb0986102ACDcD9882E2E5B5a1aD9C73',
    // OBITUARY_GOERLI = '0x1AeD1ad4a997f9b26f18e482052080383709B70F',

    SKELEPHUNKS_SEPOLIA = '0x218a56135774A6fd1a2f20Bc4783793E039D71bE',
    SKELEKEYS_SEPOLIA = '0xDc45EB6d08c5D0094131181B3ADBF162b89D2e2C',
    GRAVEYARD_SEPOLIA = '0xa2E84d714963da56650a18aE5d53A18465A3FCcB',
    DROP_SEPOLIA = '0x05703c63acA221a32B7F77209AF3201d9fa179aE',
    CATACOMBS_SEPOLIA = '0x952c959d962E79E190DD0e8689D1FAA3c7CC0BBe',
    GRIDTAG_SEPOLIA = '0xdA79be1C78922CB96305CF7b3121E088cC3a4425',
    GRIDIDENTITY_SEPOLIA = '0xd5e0862aEB98832EA4b12401256dc68cc70dB4e3',
    GRIDSTORE_SEPOLIA = '0xfe1B99eb118fE64a5Dd33feC58Ee1F51445d7DEc',
    OBITUARY_SEPOLIA = '0x4B8119b766B64790B26B7dEc5214DaFF6adb5a14',

    SKELEPHUNKS_MAINNET = '0x7db8cD89308A295bb2D7F809B05DB6389e9a6d88',
    SKELEKEYS_MAINNET = '0xbcAF0710fd84BCf7452b50308Bd3627D2DcBe5D4',
    GRAVEYARD_MAINNET = '0x3b52AC11c612a74f6F1a11AA448340BEE1fed454',
    DROP_MAINNET = '0xb88b1E6E7d2834f3B785E64a0944D251A0A3A2BE',
    CATACOMBS_MAINNET = null,
    GRIDIDENTITY_MAINNET = null,
    GRIDTAG_MAINNET = null,
    GRIDSTORE_MAINNET = null,
    OBITUARY_MAINNET = null,

    testnet = TESTNET,
    override = OVERRIDE,

    projectId = '6b12006946489aaacb1373c107c932e9',
    metadata = {
        name: 'Skelephunks',
        description: 'Skelephunks dApp',
        url: 'https://app.skelephunks.com',//'http://localhost:3000',//
        icons: ['https://avatars.githubusercontent.com/u/37784886']
    },

    wagmiChains = testnet === 'sepolia' ? [sepolia] : [mainnet],
    wagmiConfig = defaultWagmiConfig({
        chains: wagmiChains,
        // batch: {
        //     multicall: {
        //         batchSize: 100
        //     }
        // },
        metadata,
        projectId
    });
const client = wagmiConfig.getClient();
console.log(client);
export default function getENV() {
    // //console.log('>>> getENV')
    const
        skelephunksContract =
            //testnet ==='goerli' ? SKELEPHUNKS_GOERLI :
            testnet === 'sepolia' ? SKELEPHUNKS_SEPOLIA : SKELEPHUNKS_MAINNET,
        skeleKeysContract =
            //testnet === 'goerli' ? SKELEKEYS_GOERLI : 
            testnet === 'sepolia' ? SKELEKEYS_SEPOLIA : SKELEKEYS_MAINNET,
        graveyardContract =
            //testnet === 'goerli' ? GRAVEYARD_GOERLI : 
            testnet === 'sepolia' ? GRAVEYARD_SEPOLIA : GRAVEYARD_MAINNET,
        dropContract =
            //testnet === 'goerli' ? DROP_GOERLI : 
            testnet === 'sepolia' ? DROP_SEPOLIA : DROP_MAINNET,
        catacombsContract =
            //testnet === 'goerli' ? CATACOMBS_GOERLI :
            testnet === 'sepolia' ? CATACOMBS_SEPOLIA : CATACOMBS_MAINNET,
        gridIdentityContract =
            //testnet === 'goerli' ? GRIDIDENTITY_GOERLI : 
            testnet === 'sepolia' ? GRIDIDENTITY_SEPOLIA : GRIDIDENTITY_MAINNET,
        gridTagContract =
            // testnet === 'goerli' ? GRIDTAG_GOERLI :
            testnet === 'sepolia' ? GRIDTAG_SEPOLIA : GRIDTAG_MAINNET,
        gridStoreContract =
            //testnet === 'goerli' ? GRIDSTORE_GOERLI : 
            testnet === 'sepolia' ? GRIDSTORE_SEPOLIA : GRIDSTORE_MAINNET,
        obituaryContract =
            //testnet === 'goerli' ? OBITUARY_GOERLI : 
            testnet === 'sepolia' ? OBITUARY_SEPOLIA : OBITUARY_MAINNET,
        etherscan = `https://${testnet ? testnet + '.' : ''}etherscan.io`,
        opensea = `https://${testnet ? 'testnets.' : ''}opensea.io`,
        osAPI = `https://api.opensea.io/api/v1`,
        osNet = testnet || 'ethereum';

    return {
        projectId,
        override,
        testnet,
        status: {
            claims: true,
            network: true,
        },
        collection: {
            startingIndex: 1,
            provenanceFile: 'https://arweave.net/08A-xK3YjY2wTxBWAnaL_Uw-u2yuYCb0rk2kfdxV6xw/provenance/skelephunks/7afa40ff5176f2b872c12fe54937b68b6f9d720fc02a2e84eac093dde56d1a89.csv'
        },

        modules: {
            skelephunks: {
                icon: "💀",
                contract: {
                    address: skelephunksContract,
                    abi: SKELEPHUNKS_ABI
                }
            },
            keys: {
                icon: "🗝️",
                contract: {
                    address: skeleKeysContract,
                    abi: SKELEKEYS_ABI
                }
            },
            graveyard: {
                icon: "🪦",
                contract: {
                    address: graveyardContract,
                    abi: GRAVEYARD_ABI
                }
            },
            drop: {
                icon: "💧",
                contract: {
                    address: dropContract,
                    abi: DROP_ABI
                }
            },
            catacombs: {
                icon: "⚰️",
                contract: {
                    address: catacombsContract,
                    abi: CATACOMBS_ABI
                }
            },
            gridTag: {
                icon: "🏷️",
                contract: {
                    address: gridTagContract,
                    abi: GRIDTAG_ABI
                }
            },
            gridIdentity: {
                icon: "👤",
                contract: {
                    address: gridIdentityContract,
                    abi: GRIDIDENTITY_ABI
                }
            },
            gridStore: {
                icon: "📦",
                contract: {
                    address: gridStoreContract,
                    abi: GRIDSTORE_ABI
                }
            },

            obituary: {
                icon: "📰",
                contract: {
                    address: obituaryContract,
                    abi: OBITUARY_ABI
                }
            },

        },
        wagmi: {
            chains: wagmiChains,
            config: wagmiConfig,
        },
        etherscan: {
            home: etherscan,
            tx: hex => `${etherscan}/tx/${hex}`,
            address: hex => `${etherscan}/address/${hex}`,
            token: hex => `${etherscan}/token/${hex}`
        },
        opensea: {
            home: opensea,
            collection: hex => `${opensea}/collection/${hex}`,
            skelephunks: `${opensea}/collection/skelephunks-official`,
            token: (collection, id) => `${opensea}/assets/${osNet}/${collection}/${Number(id)}`,
            user: hex => `${opensea}/${hex}`,
            api: osAPI,
            refresh: (collection, id) => fetch(`${osAPI}/assets/${collection}/${Number(id)}?force_update=true`)
        },
        merkle: {
            phunks: '../data/phunks.csv',
            allow: '../data/allow.csv',
            drops: '../data/drop.csv'
        },
        promo: {
            // drop: {
            //     nav: "Get up to 3 free mints",
            //     button: "Sign up for your chance to win",
            //     link: "https://spearmint.xyz/p/glass-punk-PIVaway"
            // }
            // drop: {
            //     nav: "Get up to 3 free mints",
            //     button: "Sign up for your chance to win",
            //     link: "https://spearmint.xyz/p/glass-punk-PIVaway"
            // }
        }
    }
}