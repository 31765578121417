
import MYSKELE from "../MYSKELE";
import SKELE from "../SKELE";

export default function List({ collection, tokenIDs, balance, loadMore }) {
    //console.log('>>> List')
    const remain = balance - tokenIDs?.length;
    const type = collection.symbol();
    const borderColor = type == 'MYSKELE' ? 'rgba(255, 170, 0, 0.25)'
        : type == 'SKEYS' ? 'rgba(255, 255, 0, 0.25)'
            : type == 'SKELE' ? 'rgba(3, 255, 0, 0.25)'
                : 'black';
    return (<>
        <div className="list" style={{ border: `2px solid ${borderColor}` }}>
            {tokenIDs.map(tokenID => collection.symbol() == 'MYSKELE' ? <MYSKELE key={`list-SKELE-${tokenID}`} tokenID={tokenID} />
                : <SKELE key={`list-SKELE-${tokenID}`} tokenID={tokenID} />)
            }
        </div>
        {remain > 0 &&
            <div className="mint-info">
                Wallet has {remain} more tokens, do you want to load them?
                <div onClick={loadMore} className="cta">LOAD MORE</div>
            </div>
        }
    </>)
}