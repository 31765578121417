import { useRef, useState } from "react";
import defined from "../../../../../util/defined";

export default function ImgLoader({ src, onLoad }) {
    const loaded = useRef();
    if (!defined(src) || loaded.current == src) return
    // console.log(`preloading ${src}`)
    const loadHandler = () => {
        // console.log(`preloaded ${src}`)
        onLoad(src);
        loaded.current = src;
    }
    return (< img
        src={src}
        width="0"
        height="0"
        onLoad={loadHandler}
        loading="eager"
    />);
}